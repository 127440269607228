import React, { useEffect, useState } from "react";
import Jobs from "./components/Jobs";
import Header from "./components/Header";
import SearchBar from "./components/SearchBar";
import Filters from "./components/Filters";
import CountryUser from "./components/CountryUser";
import JobDetails from "./components/JobDetails";
import SortBy from "./components/SortBy";
import StickyFooter from "./components/StickyFooter";
import Footer from "./components/Footer";
import Register from "./components/Register";
import PostAJob from "./components/PostAJob";
import SignIn from "./components/Signin";
import Blog from "./components/Blog";
import BlogPost from "./components/BlogPost";
import VerifyRegister from "./components/VerifyRegister";
import VerifyLogin from "./components/VerifyLogin";
import Lobby from "./components/Lobby"; 
import CreditUpgrade from "./components/CreditUpgrade";
import UnsubscribeEmail from "./components/UnsubscribeEmail";
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { UserProvider } from "./components/UserContext";



function MainApp() {
  const [jobs, setJobs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterKeywords, setfilterKeywords] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [sortBy, setSortBy] = useState(null); // Add state for the selected country

  const navigate = useNavigate();

  const fetchJobs = async (filter = '', country = null, sortBy = null) => {
    try {
      let endpoint = '';
      let params = new URLSearchParams();

      // Determine the endpoint based on the selected filters and country
      if (searchTerm) {
        endpoint = `search`;
        params.append('search_term', searchTerm);
      } else if (country) {
        endpoint = `country/${country.name}`;
      } else if (filter) {
        endpoint = `${filter.replace(' ', '').toLowerCase()}`;
      } else {
        endpoint = "jobs";
      }

      // Append filter_by if it's provided
      if (filter) {
        params.append('filter_by', filter.replace(' ', '').toLowerCase());
      }

      // Append sort_by if it's provided
      if (sortBy) {
        params.append('sort_by', sortBy);
      }

      // Navigate to the determined endpoint with the selected parameters
      navigate(`/${endpoint}?${params.toString()}`);

      const response = await fetch(`https://bapi.dataremotely.com/${endpoint}?${params.toString()}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setJobs(data);
    } catch (error) {
      console.error("Failed to fetch jobs:", error);
    }
  };

  useEffect(() => {
    fetchJobs(filterKeywords.join(' '), selectedCountry, sortBy);
  }, [filterKeywords, selectedCountry, sortBy, searchTerm]);

  const addFilterKeywords = (keyword) => {
    if (!filterKeywords.includes(keyword)) {
      setfilterKeywords(prevKeywords => [...prevKeywords, keyword]);
    }
  };
  
  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
  };
  
  const deleteKeyword = (keyword) => {
    const newKeywords = filterKeywords.filter((key) => key !== keyword);
    setfilterKeywords(newKeywords);
    // Include the selectedCountry in the fetchJobs call
    fetchJobs(newKeywords.join(' '), selectedCountry, sortBy);
  };
  
  const clearAll = () => {
    setfilterKeywords([]);
    setSelectedCountry(null); // Reset the selected country
    setSortBy(null); // Reset sorting if needed
    fetchJobs();
  };

  return (
    <UserProvider>
      <div style={{ backgroundColor: 'white' }}> {/* Add this line */}
        <Header />
        <div style={{ textAlign: 'center', marginBottom: '1rem' }}> 
          <p><strong>.</strong></p>
        </div>
        <SearchBar setJobs={setJobs} />
        <Filters setKeywords={addFilterKeywords} deleteKeyword={deleteKeyword} clearAll={clearAll} selectedFilters={filterKeywords} setJobs={setJobs} />
        <CountryUser selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} />
        <SortBy sortBy={sortBy} setSortBy={setSortBy} />
        <Jobs data={jobs} setKeywords={addFilterKeywords} />
        {/*<StickyFooter />*/}
        <Footer />
      </div> {/* Close the div here */}
    </UserProvider>
  );
}

function App() {
  return (
    <Router>
      <UserProvider>
        <Routes>
          <Route path="/post-a-job" element={<PostAJob />} />
          <Route path="/register" element={<Register />} />
          <Route path="/Signin" element={<SignIn />} />
          <Route path="/verify-email/:token" element={<VerifyRegister />} />
          <Route path="/verify-login/:token" element={<VerifyLogin />} />
          <Route path="/lobby" element={<Lobby />} />
          <Route path="/unsubscribe-email" element={<UnsubscribeEmail />} />
          <Route path="/job/:id" element={<JobDetails />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogPost />} />
          <Route path="/*" element={<MainApp />} />
        </Routes>
      </UserProvider>
    </Router>
  );
}


export default App;