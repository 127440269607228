import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import styled from 'styled-components';
import profilePic from '../images/Anonymous-Profile-pic.jpg';

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  background: #FFFFFF;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 4rem;
  }
`;

const Logo = styled.h1`
  margin: 0;
  cursor: pointer;
  font-size: 2rem;
  font-weight: bold;
  color: #023E8A;
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }
`;

const HeaderButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;

  @media (min-width: 768px) {
    flex-direction: row;
    margin-top: 0;
  }
`;

const Button = styled.button`
  padding: 0.75rem 1.25rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  transition: background-color 0.3s;
  background-color: #E0F2FE;
  color: #111827;
  font-size: 1rem;

  &:hover {
    background-color: #cfe0fb;
  }
`;

const LoginButton = styled(Button)`
  padding: 1rem 1.5rem;
  font-size: 1.125rem;
  background-color: #DC2626;
  color: #FFF;
`;

const ProfilePic = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #FFF;
`;

const Header = () => {
  const navigate = useNavigate();
  const { user, setUser } = useUser();

  const handlePostJobClick = () => {
    navigate('/post-a-job');
  };

  const handleLoginClick = () => {
    navigate('/Signin');
  };

  const handleLogoutClick = () => {
    setUser(null);
    localStorage.removeItem('authToken');
  };

  const handleCvAnalystClick = () => {
    navigate('/resume-and-coverletter');
  };

  const handleBlogClick = () => {
    window.location.href = 'http://www.dataremotely.com/blog';
  };

  const handlePaidCommunityClick = () => {
    window.location.href = 'https://analyticpinnacle.thrivecart.com/data-syndicates-checkout/';
  };

  const handleDataSyndicatesClick = () => {
    window.location.href = 'https://analyticpinnacle.thrivecart.com/data-syndicates/';
  };

  const handleBillingClick = () => {
    window.location.href = 'https://analyticpinnacle.thrivecart.com/updateinfo/';
  };

  const handleAffiliateClick = () => {
    window.location.href = 'https://datacamp.pxf.io/daBGjy';
  };


  

  return (
    <HeaderContainer>
      <Logo onClick={() => navigate('/')}>Dataremotely</Logo>
      <h3 style={{ fontSize: '1rem', textAlign: 'center' }}>Remote Jobs in Data</h3>
      <HeaderButtons>
        {/*<Button onClick={handleBlogClick}>Blog</Button>*/}
        <Button onClick={handleBillingClick}>Billing</Button>
        <Button onClick={handleAffiliateClick}>Learn Data Skills For FREE</Button>
        {/*<Button onClick={handleDataSyndicatesClick}> Exclusive Access + Free Gifts</Button>*/}
        {/*<Button onClick={handleCvAnalystClick}>Cover Letter Generator</Button>*/}
        {user ? (
          <>
            <ProfilePic src={profilePic} alt="Profile" />
            <Button onClick={handleLogoutClick}>Logout</Button>
          </>
        ) : (
          <>
            {/*<Button onClick={handlePostJobClick}>Post a Job</Button>*/}
            <LoginButton onClick={handleLoginClick}>Login</LoginButton>
          </>
        )}
      </HeaderButtons>
    </HeaderContainer>
  );
};


export default Header;