// BlogPost.js
import React from 'react';
import { useParams } from 'react-router-dom';
import postsData from '../postsData.json';

const BlogPost = () => {
  const { id } = useParams();
  const post = postsData.find(post => post.id === id);

  if (!post) {
    return <div>Post not found</div>;
  }

  return (
    <div style={{ maxWidth: '50vw', margin: 'auto', padding: '1em', fontFamily: 'Arial' }}>
      <h2 style={{ fontSize: '1.5em', marginBottom: '1em' }}>{post.title}</h2>
      <p style={{ marginBottom: '1em' }}>{new Date(post.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
      <div style={{ textAlign: 'justify', lineHeight: '1.6' }} dangerouslySetInnerHTML={{ __html: post.contentHtml }} />
    </div>
  );
};

export default BlogPost;