import React, { useState, useEffect} from 'react';
import SkillTagsComponent from './SkillTags';
import Country from './Country';


const allSkills = [
    "AWS",
    "Agile Methodologies",
    "Algorithm Development",
    "Algorithmic Trading",
    "Apache Spark",
    "Azure",
    "Backup and Recovery",
    "Basic Computer Skills",
    "Basic Excel",
    "Big Data Technologies",
    "Business Intelligence",
    "Computer Vision",
    "Data Analysis",
    "Data Architect",
    "Data Cleaning",
    "Data Collection",
    "Data Governance",
    "Data Management",
    "Data Modeling",
    "Data Pipeline Building",
    "Data Security",
    "Data Visualization",
    "Data Warehousing",
    "Data Wrangling",
    "Database Administration",
    "Database Administrator",
    "Database Design",
    "Database Management",
    "Database Management Systems (Oracle, MySQL)",
    "Database Software",
    "Deep Learning",
    "Docker",
    "ETL Tools",
    "Econometrics",
    "Excel",
    "Experiment Design",
    "Financial Modeling",
    "GCP",
    "Git",
    "Hadoop",
    "Keras",
    "Linux",
    "MATLAB",
    "Machine Learning",
    "Machine Learning Algorithms",
    "Market Analysis",
    "NLP",
    "Network Management",
    "OLAP",
    "Organizational Skills",
    "Performance Tuning",
    "Power BI",
    "Predictive Modeling",
    "Probability",
    "Process Modeling",
    "Project Management",
    "PyTorch",
    "Python",
    "R",
    "Record Keeping",
    "Reporting",
    "Requirements Gathering",
    "Research Methods",
    "Risk Management",
    "Risk Management Tools",
    "Robotics",
    "SQL",
    "Shell Scripting",
    "Software Development",
    "Statistical Analysis",
    "Statistical Software (R, SPSS, SAS)",
    "Statistics",
    "Tableau",
    "TensorFlow",
    "Time Management",
    "Typing Speed and Accuracy",
    "Unix"
  ];

  const allBenefits = [
    '💰 401(k) Plan',
    '🌎 Distributed Team',
    '⏰ Asynchronous Work Environment',
    '🤓 Vision Insurance',
    '🦷 Dental Insurance',
    '🚑 Medical Insurance',
    '🏖 Unlimited Vacation Policy',
    '🏖 Paid Time Off',
    '📆 4-Day Workweek',
    '💰 401k Matching',
    '🥸 Pseudonymous Work Options',
    '💰 Profit Sharing',
    '💰 Equity Compensation',
    '🚼 Parental Leave',
    '🌟 Performance Bonuses',
    '🕊 Bereavement Leave',
    '🧳 Travel Opportunities',
    '🎓 Tuition Reimbursement',
    '🧡 Volunteer Time Off',
    '🌈 Diversity and Inclusion Programs',
    '🤝 Mentorship Opportunities',
    '🚀 Career Advancement and Promotion Opportunities',
    '🗓 Flexible Work Schedules',
    '⚕️ Health and Wellness Programs',
    '🛒 Employee Discounts and Perks on Products/Services',
    '🌟 Leadership Development Programs',
    '🌍 Global Exposure and International Project Opportunities',
];

  

const PostAJob = () => {
    const [companyName, setCompanyName] = useState('');
    const [position, setPosition] = useState('');
    const [jobType, setJobType] = useState('Full-time');
    const [positionType, setPositionType] = useState('');
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [visibleSkills, setVisibleSkills] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showSkills, setShowSkills] = useState(false);
    const [country, setCountry] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [salaryMin, setSalaryMin] = useState('');
    const [salaryMax, setSalaryMax] = useState('');
    const [jobDescription, setJobDescription] = useState('');
    const [selectedBenefits, setSelectedBenefits] = useState([]);
    const [applyUrl, setApplyUrl] = useState('');
    const [twitterUsername, setTwitterUsername] = useState('');
    const [companyEmail, setCompanyEmail] = useState('');
    const [invoiceEmail, setInvoiceEmail] = useState('');
    const [invoiceAddress, setInvoiceAddress] = useState('');
    const [invoiceNotes, setInvoiceNotes] = useState('');
    const [feedback, setFeedback] = useState('');


    // Add more state variables as needed for each field in the form

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission
        // Here you would send the form data to your server or API endpoint
    };


    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setShowSkills(true); // Show skills when typing
    };

    const filteredSkills = searchTerm.length > 0 ? allSkills.filter(skill => 
        skill.toLowerCase().includes(searchTerm.toLowerCase())
    ) : [];

    const handleSkillSelect = (skill) => {
        if (!selectedSkills.includes(skill)) {
            setSelectedSkills([...selectedSkills, skill]);
        }
        setSearchTerm(''); // Clear search term on skill select
        setShowSkills(false); // Hide skills after selection
    };

    useEffect(() => {
        setVisibleSkills(allSkills);
        console.log(selectedSkills)
    }, [selectedSkills]);


    const handleSkillRemove = (skillToRemove) => {
        setSelectedSkills(selectedSkills.filter(skill => skill !== skillToRemove));
    };

    const handleInputBlur = () => {
        // Hide the skill tags when the input is not focused, with a delay to allow click event to register
        setTimeout(() => setShowSkills(false), 200);
    };

    const handleCountrySelect = (country) => {
        console.log('handleCountrySelect called in PostAJob:', country);
        setSelectedCountry(country);
    };
    
    const handleCountryRemove = () => {
        console.log('handleCountryRemove called in PostAJob');
        setSelectedCountry(null);
    };

    const handleBenefitSelect = (benefit) => {
        if (!selectedBenefits.includes(benefit)) {
            setSelectedBenefits([...selectedBenefits, benefit]);
        } else {
            setSelectedBenefits(selectedBenefits.filter((b) => b !== benefit));
        }
    };

    const handleFeedbackChange = (event) => {
        setFeedback(event.target.value); // Update feedback state on change
    };


    

    

    // Inline CSS for form layout similar to the screenshot provided

    const SkillSearchStyle = {
        width: '100%', // Make the search box wider
        marginBottom: '10px' // Add space below the search box
    };

    const skillTagStyle = {
        display: 'inline-block',
        margin: '5px',
        padding: '5px 10px',
        border: '1px solid #ddd',
        borderRadius: '20px',
        cursor: 'pointer'
    };

    const CountrySearchStyle = {
        width: '100%', // Make the search box wider
        marginBottom: '10px' // Add space below the search box
    };

    
    const selectedCountryStyle = {
        padding: '10px',
        marginBottom: '10px', // Add space below the selected country
        background: '#eee',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    };

    
    const removeButtonStyle = {
        padding: '2px 5px',
        background: 'red',
        color: 'white',
        cursor: 'pointer',
        borderRadius: '4px',
    };

    const removeStyle = {
        display: 'block', // Make 'Remove' appear on a new line
        backgroundColor: 'red', // Red background
        color: 'white', // White text
        cursor: 'pointer',
        padding: '2px 5px',
        width: 'fit-content', // Fit to content size
        marginTop: '5px', // Space from the skill tag
        borderRadius: '4px' // Rounded corners
    };

    const formStyle = {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '600px',
        margin: 'auto',
        border: '1px solid #ddd',
        padding: '20px',
        borderRadius: '8px',
    };

    const labelStyle = {
        marginBottom: '10px',
    };

    const inputStyle = {
        padding: '10px',
        marginBottom: '20px',
        borderRadius: '4px',
        border: '1px solid #ddd',
    };

    const noteStyle = {
        fontSize: '12px',
        color: '#666',
        marginTop: '5px', // Decrease the top margin
        marginBottom: '20px',
        lineHeight: '1.5'
    };

    const jobDescriptionContainerStyle = {
        display: 'flex',
        flexDirection: 'column',  // Stack children vertically
        marginBottom: '40px'
    };

    const jobDescriptionTitleStyle = {
        marginBottom: '5px', // Space between title and text area
        textAlign: 'center'
    };

    const textAreaStyle = {
        ...inputStyle, // Spread the existing styles
        height: '400px', // Set a fixed height
        resize: 'vertical' // Allow only vertical resizing
    };


    const benefitsContainerStyle = {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))', // This will create a responsive grid
        gap: '10px',
        marginBottom: '20px',
    };

    const benefitStyle = (selected) => ({
        padding: '10px',
        border: '1px solid #ddd',
        borderRadius: '20px',
        cursor: 'pointer',
        backgroundColor: selected ? '#c8e1ff' : 'transparent', // Highlight color if selected
    });

    const UrlLabelStyle = {
        marginBottom: '10px',
        fontSize: '20px', // Increase the font size
    };

    const applyUrlStyle = {
        width: '100%',
        padding: '15px', // Increase the padding
        marginBottom: '2px',
        borderRadius: '4px',
        border: '1px solid #ddd',
        backgroundColor: '#fff',
        color: '#000',
        fontSize: '18px', // Increase the font size
    };

    const inputTextStyle = {
        padding: '10px',
        margin: '10px 0', // Adds space above and below the input
        border: '1px solid #ccc', // A light gray border that's not too harsh
        borderRadius: '4px', // Slightly rounded corners
        width: '100%', // Makes the input stretch to the full width of its container
        boxSizing: 'border-box', // Ensures padding doesn't add to the width
    };

    const feedbackBoxStyle = {
        width: '100%', // Full width
        minHeight: '150px', // Minimum height to start with, can be adjusted
        padding: '15px', // Padding inside the textarea
        marginTop: '20px', // Space above the textarea
        marginBottom: '10px', // Space below the textarea
        border: '1px solid #333', // Dark border color as seen in the screenshot
        borderRadius: '5px', // Slightly rounded corners
        background: '#fff', // Black background color
        color: '#000', // White text color
        fontSize: '1rem', // Font size similar to the rest of your form
        resize: 'vertical', // Allowing vertical resize only
    };
    
    const feedbackNoteStyle = {
        color: '#aaa', // Lighter text color for the note
        fontSize: '0.875rem', // Smaller font size for the note
        marginTop: '1px', // Space above the note
        marginBottom: '30px',
    };
    










    const submitButtonStyle = {
        padding: '10px 20px',
        background: 'black',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    };
    

    return (
        <form onSubmit={handleSubmit} style={formStyle}>
            <label style={labelStyle}>
                Company Name:
                <input
                    type="text"
                    value={companyName}
                    onChange={e => setCompanyName(e.target.value)}
                    style={inputStyle}
                    required
                />
            </label>
            <label style={labelStyle}>
                Position Title:
                <input
                    type="text"
                    value={position}
                    onChange={e => setPosition(e.target.value)}
                    style={inputStyle}
                    required
                />
            </label>
            <label style={labelStyle}>
                Job Type:
                <select
                    value={jobType}
                    onChange={e => setJobType(e.target.value)}
                    style={inputStyle}
                >
                    <option value="Full-time">Full-time</option>
                    <option value="Part-time">Part-time</option>
                    <option value="Contract">Contract</option>
                    {/* Add other job types as needed */}
                </select>
            </label>
            <label style={labelStyle}>
                Position Type:
                <select
                    value={positionType}
                    onChange={e => setPositionType(e.target.value)}
                    style={inputStyle}
                    required
                >
                    <option value="">Select a position type</option>
                    <option value="Data Analyst">Data Analyst</option>
                    <option value="Business Analyst">Business Analyst</option>
                    <option value="Data Engineer">Data Engineer</option>
                    <option value="Data Scientist">Data Scientist</option>
                    <option value="Data Entry">Data Entry</option>
                    <option value="Data Specialist">Data Specialist</option>
                    <option value="Machine Learning Engineer">Machine Learning Engineer</option>
                    <option value="AI Engineer">AI Engineer</option>
                    <option value="Quantitative Analyst">Quantitative Analyst</option>
                    <option value="Research Analyst">Research Analyst</option>
                    <option value="Risk Analyst">Risk Analyst</option>
                    <option value="Data Architect">Data Architect</option>
                    <option value="Database Administrator">Database Administrator</option>
                    <option value="BI Developer">BI Developer</option>
                    <option value="Statistician">Statistician</option>
                    {/* Add other position types as needed */}
                </select>
            </label>
            <label>
            Location:
            <Country
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
                handleCountrySelect={handleCountrySelect}
            />
        </label>
        {selectedCountry && (
                <div style={selectedCountryStyle}>
                    {selectedCountry.flag} {selectedCountry.name}
                    <button onClick={handleCountryRemove} style={removeButtonStyle}>Remove</button>
                </div>
            )}
        
        
            
            <div style={{ marginBottom: '20px' }}>
            <h3>Skill Tags or Tech Stack</h3> {/* Title */}
                <input
                    type="text"
                    placeholder="Type a tag or keyword to search and add it"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    onFocus={() => setShowSkills(true)}
                    onBlur={handleInputBlur}
                    style={SkillSearchStyle}
                />
                {showSkills && (
                    <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                        {filteredSkills.length > 0 ? filteredSkills.map(skill => (
                            <div key={skill} style={skillTagStyle} onClick={() => handleSkillSelect(skill)}>
                                {skill}
                            </div>
                        )) : (
                            // Show all skills if searchTerm is empty
                            allSkills.map(skill => (
                                <div key={skill} style={skillTagStyle} onClick={() => handleSkillSelect(skill)}>
                                    {skill}
                                </div>
                            ))
                        )}
                    </div>
                )}
                <div>
                    {selectedSkills.map(skill => (
                        <div key={skill} style={{ ...skillTagStyle, marginBottom: '10px' }}>
                            {skill} 
                            <span onClick={() => handleSkillRemove(skill)} style={removeStyle}>
                                Remove
                            </span>
                        </div>
                    ))}
                </div>
            </div>
            <p style={noteStyle}>
                Short tags are preferred. Use tags like industry and tech stack. The first 3 or 4 tags are shown on the site, 
                the other tags aren't but the job will be shown on each tag specific page (like /remote-react-jobs).
            </p>
            <label>
                Annual Salary or Compensation in USD (Gross, Annualized, Full-Time-Equivalent (FTE) in USD Equivalent)*
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <input
                        type="number"
                        placeholder="Minimum per year"
                        value={salaryMin}
                        onChange={(e) => setSalaryMin(e.target.value)}
                        style={{ ...inputStyle, marginRight: '5px' }} // Half width
                    />
                    <input
                        type="number"
                        placeholder="Maximum per year"
                        value={salaryMax}
                        onChange={(e) => setSalaryMax(e.target.value)}
                        style={{ ...inputStyle, marginLeft: '5px' }} // Half width
                    />
                </div>
            </label>

            <p style={noteStyle}>
        <strong>It's illegal to not share salary range on job posts since 2021.</strong> Posts without salary will automatically show an estimate of salary based on similar jobs. Remote job postings are legally required to show a salary compensation range in many U.S. states and countries. Google does NOT index jobs without salary data.
            </p>

            <div style={jobDescriptionContainerStyle}>
                <h3 style={jobDescriptionTitleStyle}>Job Description</h3>
                <textarea
                    value={jobDescription}
                    onChange={(e) => setJobDescription(e.target.value)}
                    style={textAreaStyle}
                />
            </div>

            <h3>Benefits</h3>
            <div style={benefitsContainerStyle}>
                {allBenefits.map((benefit, index) => (
                    <div
                        key={index}
                        style={benefitStyle(selectedBenefits.includes(benefit))}
                        onClick={() => handleBenefitSelect(benefit)}
                    >
                        {benefit}
                    </div>
                ))}
            </div>

            <label style={UrlLabelStyle}>
                Apply URL*:
                <input
                    type="url"
                    value={applyUrl}
                    onChange={(e) => setApplyUrl(e.target.value)}
                    placeholder="https://"
                    required
                    style={applyUrlStyle}
                />
            </label>
            <p style={noteStyle}>
                Apply URLs with a form an applicant can fill out generally receive a lot more applicants than having people apply by email (below). A good platform to have applicants apply on is Lever (not affiliated).
            </p>

            <label style={labelStyle}>
                Company Twitter:
                <input
                    type="text"
                    placeholder="username"
                    value={twitterUsername}
                    onChange={(e) => setTwitterUsername(e.target.value)}
                    style={inputTextStyle}
                />
            </label>

            <label style={labelStyle}>
                Company Email*:
                <input
                    type="email"
                    placeholder="Email for invoice and edit link"
                    value={companyEmail}
                    onChange={(e) => setCompanyEmail(e.target.value)}
                    required
                    style={inputTextStyle}
                />
            </label>

            <label style={labelStyle}>
                Invoice Email:
                <input
                    type="email"
                    placeholder="Email for finance department"
                    value={invoiceEmail}
                    onChange={(e) => setInvoiceEmail(e.target.value)}
                    style={inputTextStyle}
                />
            </label>

            <label style={labelStyle}>
                Invoice Address*:
                <textarea
                    placeholder="Full invoice address"
                    value={invoiceAddress}
                    onChange={(e) => setInvoiceAddress(e.target.value)}
                    required
                    style={inputTextStyle}
                />
            </label>

            <label style={labelStyle}>
                Invoice Notes / PO Number:
                <textarea
                    placeholder="e.g. PO number 1234"
                    value={invoiceNotes}
                    onChange={(e) => setInvoiceNotes(e.target.value)}
                    style={inputTextStyle}
                />
            </label>

            <div>
        <h3>FEEDBACK ABOUT DATA REMOTELY</h3>
        <textarea
            placeholder="Your feedback here..."
            style={feedbackBoxStyle}
            // Bind this to state or form handling functions if needed
        ></textarea>
        <p style={feedbackNoteStyle}>
            This isn't part of the job post. If you have any feature requests or general feedback about posting a job data remotely leave it here. Please be radically honest, I'm always improving the site and act on your feedback fast. It's most important that you're happy with the site and I want you to keep coming back to post here! This feedback box gets sent straight to my phone wherever I am. -Pieter Levels, Founder of Data Remotely
        </p>
    </div>

           
            {/* Add more input fields as needed for each field in the form */}
            <input type="submit" value="Submit" style={submitButtonStyle} />
        </form>
    );
};

export default PostAJob;
