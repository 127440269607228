import React, { useState } from 'react';
import { Link } from 'react-router-dom';


function Register() {
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [registrationStatus, setRegistrationStatus] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [AreaOfInterest, setAreaOfInterest] = useState('');


    const handleSubmit = async (event) => {
        event.preventDefault();
    
        if (isSubmitting) return;
    
        // Check if areaOfInterest is selected
        if (!AreaOfInterest) {
            window.alert('Please select your primary area of interest.');
            return; // Stop the form submission if areaOfInterest is not selected
        }
    
        setIsSubmitting(true);
    
        console.log('Attempting to register with:', email, 'and area of interest:', AreaOfInterest);
    
        const BACKEND_API_URL = 'https://bapi.dataremotely.com/register';
    
        try {
            const response = await fetch(BACKEND_API_URL, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, username, AreaOfInterest }),
            });
    
            if (!response.ok) {
                console.error('Registration failed:', response.status);
                window.alert('Registration failed');
                setIsSubmitting(false);
                return;
            }
    
            const result = await response.json();
            console.log('Registration successful:', result);
            window.alert(`Registration successful. Please check your inbox at ${email}. If you don't see the email, please check your spam folder.`);
        } catch (error) {
            console.error('Error processing registration:', error);
            window.alert('Error processing registration');
            setIsSubmitting(false);
        }
    };

    

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <h1 style={{ fontSize: '24px', margin: '0' }}>Sign up for free to see all jobs</h1>
                <p style={{ fontSize: '18px' }}>and apply for remote opportunities</p>
            </div>
            <form onSubmit={handleSubmit} style={{ width: '300px' }}>
                <input
                    type="text"
                    placeholder="First Name"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    style={{ padding: '10px', marginBottom: '10px', fontSize: '16px', width: '100%', boxSizing: 'border-box' }}
                    required
                />
                <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{ padding: '10px', marginBottom: '10px', fontSize: '16px', width: '100%', boxSizing: 'border-box' }}
                    required
                />
                <div>
            <h3 style={{ marginBottom: '10px' }}>Select your primary area of interest:</h3>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                gap: '10px'
            }}>
                {['Resume Enhancer', 'Data Analytics', 'Data Science', 'Machine Learning', 'Frontend/Backend/Full Stack Developer'].map((interest) => (
                <label key={interest} style={{
                    backgroundColor: AreaOfInterest === interest ? '#0000ff' : '#f0f0f0',
                    color: AreaOfInterest === interest ? '#ffffff' : '#000000',
                    padding: '10px',
                    borderRadius: '20px',
                    cursor: 'pointer',
                    display: 'block',
                    textAlign: 'center',
                    border: `1px solid ${AreaOfInterest === interest ? '#0000ff' : '#d0d0d0'}`,
                    minWidth: '180px',
                    transition: 'all 0.3s ease'
                }}>
                    <input
                    type="radio"
                    value={interest}
                    checked={AreaOfInterest === interest}
                    onChange={(e) => setAreaOfInterest(e.target.value)}
                    style={{ display: 'none' }}
                    />
                    {interest}
                </label>
                ))}
            </div>
            </div>
                <button
                    type="submit"
                    disabled={isSubmitting}
                    style={{ 
                        padding: '10px', fontSize: '16px', backgroundColor: 'blue', 
                        color: 'white', cursor: 'pointer', width: '100%', 
                        border: 'none', borderRadius: '4px'
                    }}
                >
                    {isSubmitting ? 'Registering...' : 'Register'}
                </button>
            </form>
            {registrationStatus && <div style={{ marginTop: '10px', textAlign: 'center' }}>{registrationStatus}</div>}
            <div style={{ marginTop: '10px', textAlign: 'center' }}>
                Already have an account?
                <Link to="/Signin" style={{ marginLeft: '5px', color: '#0000EE' }}>Login here</Link>
            </div>
        </div>
    );
}


export default Register;