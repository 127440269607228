import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using react-router for navigation



const footerStyle = {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    padding: '20px',
    borderTop: '1px solid #ccc',
    marginTop: '20px'
  };
  
  const footerSectionStyle = {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    minWidth: '150px'
  };

  const linkStyle = {
    color: 'gray', // Set the color of the link text to gray
    textDecoration: 'none', // Remove underlines from links
  };

  const titleStyle = {
    marginBottom: '20px', // Adjust the space between the title and the list
  };
  
  const listStyle = {
    listStyle: 'none', // This will remove bullet points
    padding: 0 // This will remove default padding
  };

  const listItemStyle = {
    marginBottom: '15px', // Adjust the space as needed
  };

  const communitySectionStyle = {
    ...footerSectionStyle, // This spreads the existing footer section styles
    border: '2px solid #FF5722', // Add a border with a color
    borderRadius: '8px', // Optional: round the corners of the border
    backgroundColor: '#f7f7f7', // Add a background color
    padding: '15px', // Add padding inside the border
    margin: '10px 0', // Add some margin to the top and bottom
    textAlign: 'center', // Center-align the text
  };
  
  const communityTitleStyle = {
    ...titleStyle, // This spreads the existing title styles
    fontSize: '1.5em', // Increase the font size
    color: 'blue', // Change the title color (example color)
    textAlign: 'center', // Center-align the title
  };
  
  const communityLinkStyle = {
    ...linkStyle, // This spreads the existing link styles
    color: 'red', // Change the link text color (example color)
    fontWeight: 'bold', // Make the font weight bold
  };

  const buttonStyle = {
    padding: '15px 30px', // Increased padding for a larger button
    fontSize: '1.2em', // Larger font size for the button text
    backgroundColor: 'blue', // Example button color, adjust as needed
    color: 'white',
    textDecoration: 'none',
    borderRadius: '8px', // Slightly more rounded corners (optional)
    fontWeight: 'bold',
    marginTop: '15px', // Increased space above the button
    display: 'inline-block', // To apply padding and margin correctly
    cursor: 'pointer', // Changes the cursor to signify this is a clickable element
  };


  const Footer = () => {
    return (
      <footer className="footer" style={{ padding: '20px 0' }}>
        <div className="footer-content" style={footerStyle}>
  
          {/* 
 <div style={{ width: '100%', marginBottom: '20px' }}>
            <div className="footer-section" style={communitySectionStyle}>
                <h4 style={communityTitleStyle}>Join the Skool Community</h4>
                <ul style={listStyle}>
                <li style={listItemStyle}>
                    <Link to="https://analyticpinnacle.thrivecart.com/data-syndicates-checkout/" style={communityLinkStyle}>
                    We focus on finding purpose in Data, Career Success, and Health.<br />
                    Resume Enhancer, Cover Letter Generator, Profession Email Finder.<br />
                    1 on 1 Coaching, Courses & Guides, and an Exclusive Community.
                    </Link>
                </li>
                <li style={listItemStyle}>
                    <Link to="https://analyticpinnacle.thrivecart.com/data-syndicates-checkout/" style={buttonStyle}>
                    Join
                    </Link>
                </li>
                </ul>
            </div>
            </div>
              */}
  
          {/* The rest of the footer sections */}
          <div className="footer-section" style={footerSectionStyle}>
            <h4 style={titleStyle}>DataRemotely</h4>
            <ul style={listStyle}>
              <li style={listItemStyle}><Link to="/post-a-job" style={linkStyle}>Post A Job</Link></li>
              <li style={listItemStyle}><Link to="/newsletter" style={linkStyle}>Newsletter</Link></li>
              <li style={listItemStyle}><Link to="/submit-feedback" style={linkStyle}>Submit Feedback</Link></li>
              <li style={listItemStyle}><Link to="/about" style={linkStyle}>About</Link></li>
              <li style={listItemStyle}><Link to="/contact" style={linkStyle}>Contact</Link></li>
              <li style={listItemStyle}><Link to="/faq" style={linkStyle}>Frequently Asked Questions</Link></li>
            </ul>
          </div>
  
          <div className="footer-section" style={footerSectionStyle}>
            <h4 style={titleStyle}>Resources</h4>
            <ul style={listStyle}>
              <li style={listItemStyle}><Link to="/courses" style={linkStyle}>Courses</Link></li>
              <li style={listItemStyle}><Link to="/blog" style={linkStyle}>Blog</Link></li>
            </ul>
          </div>
  
          <div className="footer-section" style={footerSectionStyle}>
          <h4 style={titleStyle}>Data Job Roles</h4>
          <ul style={listStyle}>
            <li style={listItemStyle}><Link to="/datascientist?filter_by=datascientist" style={linkStyle}>Remote Data Scientist Jobs</Link></li>
            <li style={listItemStyle}><Link to="/dataanalyst?filter_by=dataanalyst" style={linkStyle}>Remote Data Analyst Jobs</Link></li>
            <li style={listItemStyle}><Link to="/dataentry?filter_by=dataentry" style={linkStyle}>Remote Data Entry Jobs</Link></li>
            <li style={listItemStyle}><Link to="/dataengineer?filter_by=dataengineer" style={linkStyle}>Remote Data Engineer Jobs</Link></li>
            <li style={listItemStyle}><Link to="/businessanalyst?filter_by=businessanalyst" style={linkStyle}>Remote Business Analyst Jobs</Link></li>
            <li style={listItemStyle}><Link to="/machinelearning?filter_by=machinelearning" style={linkStyle}>Remote Machine Learning Jobs</Link></li>
          </ul>
        </div>

        <div className="footer-section" style={footerSectionStyle}>
          <h4 style={titleStyle}>Data Job Location</h4>
          <ul style={listStyle}>
            <li style={listItemStyle}><Link to="/country/Canada" style={linkStyle}>Remote Canada Data Jobs</Link></li>
            <li style={listItemStyle}><Link to="/country/India" style={linkStyle}>Remote India Data Jobs</Link></li>
            <li style={listItemStyle}><Link to="/country/Nigeria" style={linkStyle}>Remote Nigeria Data Jobs</Link></li>
            <li style={listItemStyle}><Link to="/country/United%20States" style={linkStyle}>Remote United States Data Jobs</Link></li>
            <li style={listItemStyle}><Link to="/country/United%20Kingdom" style={linkStyle}>Remote United Kingdom Data Jobs</Link></li>
            <li style={listItemStyle}><Link to="/country/Worldwide" style={linkStyle}>International Remote Data Jobs</Link></li>
          </ul>
        </div>

       
                </div>

            <div className="footer-bottom" style={{ textAlign: 'center', marginTop: '20px' }}>
                <p>© 2024 Dataremotely. All Rights Reserved</p>
                <ul className="footer-bottom-links" style={listStyle}>
                    <li style={listItemStyle}><Link to="/terms" style={linkStyle}>Terms of use</Link></li>
                    <li style={listItemStyle}><Link to="/privacy" style={linkStyle}>Privacy policy</Link></li> 
                    {/* Social Media Links */}
                    {/* Add your social media links here */}
                </ul>
                </div>
                </footer>
                );
                };

                export default Footer;