import React, { useState } from 'react';

function SearchBar({ setJobs }) {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = async () => {
    try {
      const response = await fetch(`https://bapi.dataremotely.com/search?search_term=${searchTerm}`);
      const jobs = await response.json();
      setJobs(jobs);
    } catch (error) {
      console.error('Error searching jobs:', error);
    }
  };

  const handleClear = async () => {
    setSearchTerm('');
    try {
      const response = await fetch(`https://bapi.dataremotely.com/jobs`);
      const jobs = await response.json();
      setJobs(jobs);
    } catch (error) {
      console.error('Error fetching jobs:', error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const searchInputStyle = {
    marginRight: '10px',
    padding: '15px',
    fontSize: '1rem',
    width: '100%', // Responsive width
    maxWidth: '400px', // Max width for larger screens
    border: '1px solid #d1d5db', // subtle border
    borderRadius: '8px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)'
  };

  const actionButtonStyle = {
    padding: '15px 25px',
    fontSize: '1rem',
    border: 'none',
    borderRadius: '8px',
    backgroundColor: '#E0F2FE', // Styled to match the filter buttons
    color: 'black',
    cursor: 'pointer',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    marginLeft: '10px',
    textTransform: 'uppercase', // Matching the filter buttons
    fontWeight: '600', // Matching the filter buttons
    transition: 'background-color 0.3s', // Smooth transition for hover effect
  };

  const clearButtonStyle = {
    ...actionButtonStyle,
    backgroundColor: '#d9d9d9', // Soft gray for the clear button
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px' }}>
        <input 
          type="text" 
          placeholder="Search for jobs..." 
          value={searchTerm} 
          onChange={e => setSearchTerm(e.target.value)}
          onKeyPress={handleKeyPress}
          style={searchInputStyle}
        />
        <button onClick={handleSearch} style={actionButtonStyle}>
          Search
        </button>
        <button onClick={handleClear} style={clearButtonStyle}>
          Clear
        </button>
      </div>
      <p style={{ textAlign: 'center', color: '#6b7280' }}>
        Note: The search bar is best used for finding specific job postings (15 Max). Use filters for a broader job search.
      </p>
    </>
  );
}

export default SearchBar;
