import React, { useEffect } from 'react';
import { useUser } from './UserContext'; // Import useUser
import { useParams, useNavigate } from 'react-router-dom';

function VerifyLogin() {
    const { setUser } = useUser();
    const { token } = useParams();
    const navigate = useNavigate(); // Add this line

    useEffect(() => {
        const verifyLogin = async () => {
            const response = await fetch(`https://bapi.dataremotely.com/verify-login/${token}`);
    
            if (response.ok) {
                const result = await response.json();
                setUser({ token: result.access_token });
                localStorage.setItem('authToken', result.access_token); // Add this line
                navigate('/'); // Navigate to the home page
                window.location.reload();
            } else {
                console.error('Verification failed:', response.status);
            }
        };
    
        verifyLogin();
    }, [token, setUser, navigate]); // Add navigate to the dependency array

    return (
        <div>
            Verifying login...
        </div>
    );
}

export default VerifyLogin;