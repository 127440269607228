import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const JobDetails = () => {
  const { id } = useParams();
  const [job, setJob] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const response = await fetch(`https://bapi.dataremotely.com/jobdetails/${id}`);
        {/*const response = await fetch(`http://localhost:8000/jobdetails/${id}`);*/}

        if (response.ok) {
          const data = await response.json();
          setJob(data);
          console.log(data); // Log the data to see what is received
        } else {
          // If the response is not ok, handle the error
          setError('Job details not found.');
          navigate('/not-found');
        }
      } catch (error) {
        console.error("Error fetching job details:", error);
        setError(error.message);
      }
    };

    fetchJobDetails();
  }, [id, navigate]);

  // If there's an error, display it
  if (error) return <div>Error: {error}</div>;

  // Ensure job data is loaded before attempting to render
  if (!job) return <div>Loading...</div>;

  // Function to split the requirements text into sections and list items
  const parseAndFormatText = (text) => {
    // Split by two newlines to get separate paragraphs or sections
    const paragraphs = text.split('\n\n');
  
    // Map each paragraph to a JSX element
    const formattedText = paragraphs.map((paragraph, index) => {
      // Check for section headers (ending with a colon)
      if (paragraph.match(/.+:$/)) {
        return <h2 key={index} style={{ fontWeight: 'bold', marginTop: '20px' }}>{paragraph}</h2>;
      }
      
      // Split by bullet points if they exist
      const bullets = paragraph.split(/•|-/);
      if (bullets.length > 1) {
        // Remove the first element if it's empty (happens if paragraph starts with a bullet point)
        if (bullets[0].trim() === '') bullets.shift();
  
        return (
          <ul key={index} style={{ paddingLeft: '20px' }}>
            {bullets.map((bullet, bulletIndex) => (
              <li key={bulletIndex}>{bullet.trim()}</li>
            ))}
          </ul>
        );
      }
  
      // Default case: just a paragraph
      return <p key={index}>{paragraph}</p>;
    });
  
    return formattedText;
  };
  

  // Define a style object for each section of your component
  const styles = {
    container: {
      backgroundColor: 'white',
      boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
      borderRadius: '4px',
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
      lineHeight: '1.5',
      maxWidth: '700px',
      margin: '20px auto',
    },
    header: {
      marginBottom: '20px',
    },
    title: {
      fontSize: '24px',
      fontWeight: 'bold',
      marginBottom: '10px',
    },
    subtitle: {
      fontSize: '18px',
      color: '#666',
      marginBottom: '10px',
    },
    details: {
      marginBottom: '20px',
      color: '#333',
    },
    sectionHeader: {
      fontWeight: 'bold',
      marginTop: '20px',
      marginBottom: '10px',
    },
    list: {
      listStyleType: 'none',
      paddingLeft: '20px',
      marginBottom: '20px',
    },
    listItem: {
      marginBottom: '10px',
    },
    applyButton: {
      display: 'block',
      width: '100%',
      padding: '10px 0',
      backgroundColor: '#0056b3',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      fontSize: '16px',
      textDecoration: 'none',
      textAlign: 'center',
    },
    backButton: {
      textDecoration: 'none',
      color: '#0056b3',
      display: 'block',
      marginBottom: '20px',
    },
  };

  // ... useEffect

  // If there's an error, display it
  if (error) return <div>Error: {error}</div>;
  if (!job) return <div>Loading...</div>;

  return (
    <div style={styles.container}>
      <a href="#" onClick={() => navigate(-1)} style={styles.backButton}>
        Back to jobs
      </a>
      <div style={styles.header}>
        <div style={styles.title}>{job.title}</div>
        <div style={styles.subtitle}>{job.company}</div>
        <div style={styles.details}>
          {job.location} • {job.country} • {new Date(job.posted_date).toLocaleDateString()}
        </div>
      </div>
      <div>
        {/* Use your parseAndFormatText function here */}
        {job.requirements && parseAndFormatText(job.requirements)}
      </div>
      <a href={job.jobappurl} style={styles.applyButton}>
        Apply Now
      </a>
    </div>
  );
};

export default JobDetails;
