import React from 'react';
import Job from "./Job";

const Jobs = ({ data }) => {
    return (
      <div className="jobs">
        {data.length > 0 ? (
          data.map((job, index) => (
            <Job key={index} job={job} />
          ))
        ) : (
          <div>No jobs found</div>
        )}
      </div>
    );
  };

export default Jobs;