import React from 'react';

const Lobby = () => {
    const handleRegisterClick = () => {
        window.location.href = "https://analyticpinnacle.thrivecart.com/cv-analyst/";
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            textAlign: 'center'
        }}>
            <h1>Hi!</h1>
            <p>To continue signing up to Dataremotely Click Here</p>
            <button onClick={handleRegisterClick} style={{
                padding: '10px 20px',
                fontSize: '16px',
                backgroundColor: '#023E8A', // Using the color you provided
                color: 'white',
                cursor: 'pointer',
                border: 'none',
                borderRadius: '4px',
                marginTop: '20px'
            }}>
                Register Me
            </button>
        </div>
    );
};

export default Lobby;