import React, { useState, useEffect } from 'react';

const CountryUser = ({ selectedCountry, setSelectedCountry }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showCountries, setShowCountries] = useState(false);
  const [displayedCountries, setDisplayedCountries] = useState([]);
  const [isFocused, setIsFocused] = useState(false);

  const allCountries = [
    { name: 'Canada', flag: '🇨🇦' },
    { name: 'India', flag: '🇮🇳' },
    { name: 'Netherlands', flag: '🇳🇱' },
    { name: 'Spain', flag: '🇪🇸' },
    { name: 'United Kingdom', flag: '🇬🇧' },
    { name: 'United States', flag: '🇺🇸' },
  ];

  useEffect(() => {
    const filteredCountries = searchTerm === ''
      ? allCountries
      : allCountries.filter(country =>
          country.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
    setDisplayedCountries(filteredCountries);
  }, [searchTerm]);

  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    setShowCountries(false);
    setSearchTerm(''); // Clear the search after selection for better UX
  };
    
  const handleCountryRemove = () => {
    setSelectedCountry(null);
    setSearchTerm(''); // Clear the search upon removal for consistency
  };
    
  const handleFocus = () => {
    setShowCountries(true);
    setIsFocused(true);
  };
    
  const handleBlur = () => {
    setTimeout(() => {
      setIsFocused(false);
      setShowCountries(false);
    }, 200); // Delay hiding the list to allow for selection
  };

  const containerStyle = {
    position: 'relative',
    maxWidth: '300px',
    margin: '0 auto 20px', // Center the component with automatic horizontal margins
    padding: '0',
    listStyle: 'none',
  };

  const inputStyle = {
    width: '100%',
    padding: '10px',
    marginBottom: '10px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
  };

  const selectedCountryStyle = {
    padding: '10px',
    marginBottom: '10px',
    background: '#f3f4f6', // A lighter background for selected country
    border: '1px solid #ddd',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  };

  const removeButtonStyle = {
    padding: '2px 5px',
    background: 'red',
    color: 'white',
    cursor: 'pointer',
    borderRadius: '4px',
    marginLeft: '10px',
  };
      
  const listStyle = {
    width: '100%',
    padding: '10px',
    background: '#f3f4f6', // A lighter background for the list
    border: '1px solid #ddd',
    borderRadius: '4px',
    position: 'absolute',
    zIndex: '1000', // Ensure the list is above other elements
    display: showCountries ? 'block' : 'none',
  };
      
  const countryStyle = {
    padding: '10px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      background: '#e2e8f0', // Slightly darker background on hover for better UX
    }
  };

  return (
    <div style={containerStyle}>
      {!selectedCountry ? (
        <>
          <input
            type="text"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder="Type/Select a country"
            style={inputStyle}
          />
          <ul style={listStyle}>
            {displayedCountries.map((country) => (
              <li
                key={country.name}
                style={countryStyle}
                onClick={() => handleCountrySelect(country)}
                tabIndex="0" // Make the list items focusable for accessibility
              >
                {country.flag} {country.name}
              </li>
            ))}
          </ul>
        </>
      ) : (
        <div style={selectedCountryStyle}>
          {selectedCountry.flag} {selectedCountry.name}
          <button onClick={handleCountryRemove} style={removeButtonStyle}>
            Remove
          </button>
        </div>
      )}
    </div>
  );
};

export default CountryUser;
