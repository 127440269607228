import React from 'react';
import close from './images/icon-remove.svg';

const Filters = ({ setKeywords, deleteKeyword, clearAll, selectedFilters }) => {
  const jobCategories = [
    "Data Analyst",
    "Data Entry",
    "Data Engineer",
    "Data Scientist",
    "Business Analyst",
    "Machine Learning",
    "Software Engineer",
    "Web Developer"
  ];

  const handleFilterClick = (category) => {
    clearAll();
    setKeywords(category);
  };

  const handleFilterRemove = (category, event) => {
    event.stopPropagation();
    deleteKeyword(category);
  };

  const handleClear = () => {
    clearAll();
  };

  const filterStyle = {
    margin: '0.5rem',
    padding: '0.5rem 1rem',
    backgroundColor: '#E0F2FE', // Light baby blue background
    borderRadius: '20px',
    cursor: 'pointer',
    fontSize: '0.9rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    transition: 'background-color 0.3s ease',
    fontWeight: '500', // Slightly bolder font for better readability
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)', // Soft shadow for depth
  };

  const selectedFilterStyle = {
    ...filterStyle,
    backgroundColor: '#1E40AF', // Darker blue for selected state
    color: '#FFFFFF',
  };

  const clearButtonStyle = {
    ...filterStyle,
    backgroundColor: '#60A5FA', // A different shade of blue to stand out
    color: '#FFFFFF',
    fontWeight: 'bold',
  };

  return (
    <div className="filters" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', margin: '1rem 0'}}>
      {jobCategories.map((category) => (
        <div 
          key={category} 
          style={selectedFilters.includes(category) ? selectedFilterStyle : filterStyle}
          onClick={() => handleFilterClick(category)}
        >
          {category}
          {selectedFilters.includes(category) && (
            <img
              src={close}
              alt="Remove this filter"
              style={{width: '14px', height: '14px', cursor: 'pointer'}}
              onClick={(e) => handleFilterRemove(category, e)}
            />
          )}
        </div>
      ))}
      {selectedFilters.length > 0 && (
        <button
          style={clearButtonStyle}
          onClick={handleClear}
        >
          Clear All
        </button>
      )}
    </div>
  );
};

export default Filters;
