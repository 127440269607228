import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useUser } from './UserContext'; // Import useUser


function Login() {
    const [email, setEmail] = useState('');
    const [loginStatus, setLoginStatus] = useState('');
    const { setUser } = useUser();

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log('Attempting to login with:', email);
    
        const BACKEND_API_URL = 'https://bapi.dataremotely.com/request-magic-link';
    
        try {
            const response = await fetch(BACKEND_API_URL, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email }),
            });
    
            const result = await response.json();
    
            if (response.ok) {
                console.log('Login successful:', result);
                setLoginStatus('Login successful. Please check your inbox.');
                window.alert(`Login successful. Please check your inbox at ${email}`);
                localStorage.setItem('authToken', result.access_token);
            } else {
                console.error('Login failed:', response.status);
                // Set loginStatus based on the error message from the backend
                setLoginStatus(result.message);
                window.alert(result.message);
            }
        } catch (error) {
            console.error('Error processing login:', error);
            setLoginStatus('Error processing login');
            window.alert('Error processing login');
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <h1>Login</h1>
            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', width: '300px' }}>
                <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{ padding: '10px', marginBottom: '10px', fontSize: '16px' }}
                    required
                />
                <button type="submit" style={{ padding: '10px', fontSize: '16px', backgroundColor: 'blue', color: 'white', cursor: 'pointer' }}>
                    Login
                </button>
            </form>
            {loginStatus && <div>{loginStatus}</div>}
            <div style={{ marginTop: '40px', fontSize: '18px', fontWeight: 'bold' }}>
                Don't Have an Account? 
                <a href="https://analyticpinnacle.thrivecart.com/cv-analyst/" style={{ marginLeft: '20px', color: 'red', textDecoration: 'underline' }}>
                    Register here
                </a>
            </div>
        </div>
    );
}

export default Login;