// Blog.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import postsData from '../postsData.json';

const Blog = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const sortedPosts = postsData.sort((a, b) => new Date(b.date) - new Date(a.date));
    setPosts(sortedPosts);
  }, []);

  const blogStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Centers the posts horizontally
    justifyContent: 'center', // Centers the posts vertically if needed
    height: '100vh', // Takes at least full viewport height
    padding: '20px',
  };

  const postStyle = {
    width: '60%', // Set a max-width for posts for better readability
    padding: '20px',
    margin: '10px 0', // Adds space between posts
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', // Adds a subtle shadow to each post
    borderRadius: '10px', // Rounds the corners of the post boxes
    textAlign: 'center', // Center text
    background: '#f9f9f9' // Light background for the posts
  };

  return (
    <div style={blogStyle}>
      {posts.map((post, index) => (
        <div key={index} style={postStyle}>
          <Link to={`/blog/${post.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
            <h2>{post.title}</h2>
          </Link>
          <p>{new Date(post.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
        </div>
      ))}
    </div>
  );
};

export default Blog;
