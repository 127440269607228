import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

const Job = ({ job }) => {
  const {
    id,
    title,
    company,
    location,
    jobtype,
    remote,
    jobappurl,
    posted_date,
    company_logo_url,
    tags,
    country
  } = job;

  const structuredData = {
    "@context": "http://schema.org/",
    "@type": "JobPosting",
    "title": title,
    "description": job.requirements || 'Job description not available',
    "datePosted": posted_date,
    "validThrough": job.apply_deadline ? job.apply_deadline.toISOString() : null,
    "employmentType": job.employment_type,
    "hiringOrganization": {
      "@type": "Organization",
      "name": company,
      "logo": company_logo_url,
      "url": job.company_website
    },
    "jobLocation": {
      "@type": "Place",
      "address": {
        "@type": "PostalAddress",
        "addressLocality": job.city,
        "addressRegion": job.state,
        "addressCountry": country
      }
    },
    "baseSalary": job.salary ? {
      "@type": "MonetaryAmount",
      "currency": "USD",
      "value": {
        "@type": "QuantitativeValue",
        "value": job.salary,
        "unitText": "YEAR"
      }
    } : null,
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  let keywords = tags ? tags.split(',') : [];
  const countryFlagUrl = country 
  ? country.toLowerCase() === 'ng'
    ? 'https://cdn.pixabay.com/animation/2022/09/01/03/09/03-09-39-223_512.gif'
    : country.toLowerCase() === 'gb'
      ? 'https://cdn.pixabay.com/animation/2022/09/16/16/43/16-43-32-777_512.gif'
    : country.toLowerCase() === 'es'
      ? 'https://www.worldometers.info/img/flags/sp-flag.gif'
      : `https://www.worldometers.info/img/flags/${country.toLowerCase()}-flag.gif`
  : '';

  const navigate = useNavigate();

  const handleJobApplyClick = () => {
    const userToken = localStorage.getItem('authToken');
  
    if (userToken) {
      // If the user is authenticated, open the job application URL in a new window/tab
      window.open(jobappurl, '_blank');
    } else {
      // If the user is not authenticated, navigate to the register page
      navigate("/lobby");
    }
  };

  const handleImageError = (e) => {
    e.target.onerror = null; // Prevent infinite callback loop
    e.target.src = 'https://via.placeholder.com/50?text=NA'; // Placeholder image if the logo fails to load
  };

  return (
    <div className="job-container">
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div className="logo">
        <img 
          src={company_logo_url ? company_logo_url : 'https://via.placeholder.com/50?text=NA'} 
          alt={`${company} logo`} 
          onError={handleImageError}
          loading="lazy"
        />
      </div>
      <div className="job-details-container">
        <div className="job-details">
          <div className="company">
            <span className="cname">{company}</span>
          </div>
          <div className="position">{title}</div>
          <div className="details">
            <span>{formatDate(posted_date)}</span>
            <span>&nbsp;•&nbsp;</span>
            {/* The line below is commented out to not display the city location */}
            {/*<span>{location}</span>*/}
            <span>&nbsp;•&nbsp;</span>
            <span>
              <img className="flag" src={countryFlagUrl} alt={`${country} flag`} />
              {country}
            </span>
            <span>&nbsp;•&nbsp;</span>
            <span>{jobtype}</span>
          </div>
        </div>
        <div className="job-action-container">
          <div className="job-tags">
            {keywords.map((key, index) => (
              <span key={index}>{key.trim()}</span>
            ))}
          </div>
          <div className="apply-button">
        <button onClick={handleJobApplyClick} style={{backgroundColor: 'red', color: 'white'}}>Apply</button>
      </div>
        </div>
      </div>
    </div>
  );
};

export default Job;
