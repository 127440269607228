import React from 'react';

function SortBy({ sortBy, setSortBy }) {
  const handleSortChange = (event) => {
    setSortBy(event.target.value);
  };

  const sortByStyle = {
    width: '100%',
    maxWidth: '200px', // Ensures the dropdown does not get too wide
    padding: '8px 12px',
    marginBottom: '20px',
    background: '#ffffff',
    border: '2px solid #e5e7eb', // Light grey border
    borderRadius: '8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // subtle shadow for depth
    cursor: 'pointer', // Indicates that the element is interactive
  };

  const labelStyle = {
    display: 'block', // ensures the label is on top of the select
    textAlign: 'center', // centers the label text
    marginBottom: '8px', // space between the label and the select dropdown
    color: '#374151', // dark grey for better readability
    fontWeight: '500', // Slightly bolder to match modern trends
  };

  return (
    <div style={{ textAlign: 'center', padding: '10px' }}>
      <label htmlFor="sort" style={labelStyle}>Sort by:</label>
      <select id="sort" style={sortByStyle} value={sortBy || ''} onChange={handleSortChange}>
        <option value="">Select</option>
        <option value="asc">Oldest First</option>
        <option value="desc">Newest First</option>
      </select>
    </div>
  );
}

export default SortBy;
