import React, { useState, useEffect } from 'react';


const Country = ({ selectedCountry, setSelectedCountry }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [showCountries, setShowCountries] = useState(false);
    const [displayedCountries, setDisplayedCountries] = useState([]);
    const [isFocused, setIsFocused] = useState(false);

    // A list of countries and their flag emojis
    const allCountries = [
        { name: 'Afghanistan', flag: '🇦🇫' },
        { name: 'Albania', flag: '🇦🇱' },
        { name: 'Algeria', flag: '🇩🇿' },
        { name: 'Andorra', flag: '🇦🇩' },
        { name: 'Angola', flag: '🇦🇴' },
        { name: 'Antigua and Barbuda', flag: '🇦🇬' },
        { name: 'Argentina', flag: '🇦🇷' },
        { name: 'Armenia', flag: '🇦🇲' },
        { name: 'Australia', flag: '🇦🇺' },
        { name: 'Austria', flag: '🇦🇹' },
        { name: 'Azerbaijan', flag: '🇦🇿' },
        { name: 'Bahamas', flag: '🇧🇸' },
        { name: 'Bahrain', flag: '🇧🇭' },
        { name: 'Bangladesh', flag: '🇧🇩' },
        { name: 'Barbados', flag: '🇧🇧' },
        { name: 'Belarus', flag: '🇧🇾' },
        { name: 'Belgium', flag: '🇧🇪' },
        { name: 'Belize', flag: '🇧🇿' },
        { name: 'Benin', flag: '🇧🇯' },
        { name: 'Bhutan', flag: '🇧🇹' },
        { name: 'Bolivia', flag: '🇧🇴' },
        { name: 'Bosnia and Herzegovina', flag: '🇧🇦' },
        { name: 'Botswana', flag: '🇧🇼' },
        { name: 'Brazil', flag: '🇧🇷' },
        { name: 'Brunei', flag: '🇧🇳' },
        { name: 'Bulgaria', flag: '🇧🇬' },
        { name: 'Burkina Faso', flag: '🇧🇫' },
        { name: 'Burundi', flag: '🇧🇮' },
        { name: 'Cabo Verde', flag: '🇨🇻' },
        { name: 'Cambodia', flag: '🇰🇭' },
        { name: 'Cameroon', flag: '🇨🇲' },
        { name: 'Canada', flag: '🇨🇦' },
        { name: 'Central African Republic', flag: '🇨🇫' },
        { name: 'Chad', flag: '🇹🇩' },
        { name: 'Chile', flag: '🇨🇱' },
        { name: 'China', flag: '🇨🇳' },
        { name: 'Colombia', flag: '🇨🇴' },
        { name: 'Comoros', flag: '🇰🇲' },
        { name: 'Congo, Democratic Republic of the', flag: '🇨🇩' },
        { name: 'Congo, Republic of the', flag: '🇨🇬' },
        { name: 'Costa Rica', flag: '🇨🇷' },
        { name: 'Croatia', flag: '🇭🇷' },
        { name: 'Cuba', flag: '🇨🇺' },
        { name: 'Cyprus', flag: '🇨🇾' },
        { name: 'Czech Republic', flag: '🇨🇿' },
        { name: 'Denmark', flag: '🇩🇰' },
        { name: 'Djibouti', flag: '🇩🇯' },
        { name: 'Dominica', flag: '🇩🇲' },
        { name: 'Dominican Republic', flag: '🇩🇴' },
        { name: 'East Timor', flag: '🇹🇱' },
        { name: 'Ecuador', flag: '🇪🇨' },
        { name: 'Egypt', flag: '🇪🇬' },
        { name: 'El Salvador', flag: '🇸🇻' },
        { name: 'Equatorial Guinea', flag: '🇬🇶' },
        { name: 'Eritrea', flag: '🇪🇷' },
        { name: 'Estonia', flag: '🇪🇪' },
        { name: 'Eswatini', flag: '🇸🇿' },
        { name: 'Ethiopia', flag: '🇪🇹' },
        { name: 'Fiji', flag: '🇫🇯' },
        { name: 'Finland', flag: '🇫🇮' },
        { name: 'France', flag: '🇫🇷' },
        { name: 'Gabon', flag: '🇬🇦' },
        { name: 'Gambia', flag: '🇬🇲' },
        { name: 'Georgia', flag: '🇬🇪' },
        { name: 'Germany', flag: '🇩🇪' },
        { name: 'Ghana', flag: '🇬🇭' },
        { name: 'Greece', flag: '🇬🇷' },
        { name: 'Grenada', flag: '🇬🇩' },
        { name: 'Guatemala', flag: '🇬🇹' },
        { name: 'Guinea', flag: '🇬🇳' },
        { name: 'Guinea-Bissau', flag: '🇬🇼' },
        { name: 'Guyana', flag: '🇬🇾' },
        { name: 'Haiti', flag: '🇭🇹' },
        { name: 'Honduras', flag: '🇭🇳' },
        { name: 'Hungary', flag: '🇭🇺' },
        { name: 'Iceland', flag: '🇮🇸' },
        { name: 'India', flag: '🇮🇳' },
        { name: 'Indonesia', flag: '🇮🇩' },
        { name: 'Iran', flag: '🇮🇷' },
        { name: 'Iraq', flag: '🇮🇶' },
        { name: 'Ireland', flag: '🇮🇪' },
        { name: 'Israel', flag: '🇮🇱' },
        { name: 'Italy', flag: '🇮🇹' },
        { name: 'Jamaica', flag: '🇯🇲' },
        { name: 'Japan', flag: '🇯🇵' },
        { name: 'Jordan', flag: '🇯🇴' },
        { name: 'Kazakhstan', flag: '🇰🇿' },
        { name: 'Kenya', flag: '🇰🇪' },
        { name: 'Kiribati', flag: '🇰🇮' },
        { name: 'Kosovo', flag: '🇽🇰' },
        { name: 'Kuwait', flag: '🇰🇼' },
        { name: 'Kyrgyzstan', flag: '🇰🇬' },
        { name: 'Laos', flag: '🇱🇦' },
        { name: 'Latvia', flag: '🇱🇻' },
        { name: 'Lebanon', flag: '🇱🇧' },
        { name: 'Lesotho', flag: '🇱🇸' },
        { name: 'Liberia', flag: '🇱🇷' },
        { name: 'Libya', flag: '🇱🇾' },
        { name: 'Liechtenstein', flag: '🇱🇮' },
        { name: 'Lithuania', flag: '🇱🇹' },
        { name: 'Luxembourg', flag: '🇱🇺' },
        { name: 'Madagascar', flag: '🇲🇬' },
        { name: 'Malawi', flag: '🇲🇼' },
        { name: 'Malaysia', flag: '🇲🇾' },
        { name: 'Maldives', flag: '🇲🇻' },
        { name: 'Mali', flag: '🇲🇱' },
        { name: 'Malta', flag: '🇲🇹' },
        { name: 'Marshall Islands', flag: '🇲🇭' },
        { name: 'Mauritania', flag: '🇲🇷' },
        { name: 'Mauritius', flag: '🇲🇺' },
        { name: 'Mexico', flag: '🇲🇽' },
        { name: 'Micronesia', flag: '🇫🇲' },
        { name: 'Moldova', flag: '🇲🇩' },
        { name: 'Monaco', flag: '🇲🇨' },
        { name: 'Mongolia', flag: '🇲🇳' },
        { name: 'Montenegro', flag: '🇲🇪' },
        { name: 'Morocco', flag: '🇲🇦' },
        { name: 'Mozambique', flag: '🇲🇿' },
        { name: 'Myanmar', flag: '🇲🇲' },
        { name: 'Namibia', flag: '🇳🇦' },
        { name: 'Nauru', flag: '🇳🇷' },
        { name: 'Nepal', flag: '🇳🇵' },
        { name: 'Netherlands', flag: '🇳🇱' },
        { name: 'New Zealand', flag: '🇳🇿' },
        { name: 'Nicaragua', flag: '🇳🇮' },
        { name: 'Niger', flag: '🇳🇪' },
        { name: 'Nigeria', flag: '🇳🇬' },
        { name: 'North Korea', flag: '🇰🇵' },
        { name: 'North Macedonia', flag: '🇲🇰' },
        { name: 'Norway', flag: '🇳🇴' },
        { name: 'Oman', flag: '🇴🇲' },
        { name: 'Pakistan', flag: '🇵🇰' },
        { name: 'Palau', flag: '🇵🇼' },
        { name: 'Palestine', flag: '🇵🇸' },
        { name: 'Panama', flag: '🇵🇦' },
        { name: 'Papua New Guinea', flag: '🇵🇬' },
        { name: 'Paraguay', flag: '🇵🇾' },
        { name: 'Peru', flag: '🇵🇪' },
        { name: 'Philippines', flag: '🇵🇭' },
        { name: 'Poland', flag: '🇵🇱' },
        { name: 'Portugal', flag: '🇵🇹' },
        { name: 'Qatar', flag: '🇶🇦' },
        { name: 'Romania', flag: '🇷🇴' },
        { name: 'Russia', flag: '🇷🇺' },
        { name: 'Rwanda', flag: '🇷🇼' },
        { name: 'Saint Kitts and Nevis', flag: '🇰🇳' },
        { name: 'Saint Lucia', flag: '🇱🇨' },
        { name: 'Saint Vincent and the Grenadines', flag: '🇻🇨' },
        { name: 'Samoa', flag: '🇼🇸' },
        { name: 'San Marino', flag: '🇸🇲' },
        { name: 'Sao Tome and Principe', flag: '🇸🇹' },
        { name: 'Saudi Arabia', flag: '🇸🇦' },
        { name: 'Senegal', flag: '🇸🇳' },
        { name: 'Serbia', flag: '🇷🇸' },
        { name: 'Seychelles', flag: '🇸🇨' },
        { name: 'Sierra Leone', flag: '🇸🇱' },
        { name: 'Singapore', flag: '🇸🇬' },
        { name: 'Slovakia', flag: '🇸🇰' },
        { name: 'Slovenia', flag: '🇸🇮' },
        { name: 'Solomon Islands', flag: '🇸🇧' },
        { name: 'Somalia', flag: '🇸🇴' },
        { name: 'South Africa', flag: '🇿🇦' },
        { name: 'South Korea', flag: '🇰🇷' },
        { name: 'South Sudan', flag: '🇸🇸' },
        { name: 'Spain', flag: '🇪🇸' },
        { name: 'Sri Lanka', flag: '🇱🇰' },
        { name: 'Sudan', flag: '🇸🇩' },
        { name: 'Suriname', flag: '🇸🇷' },
        { name: 'Sweden', flag: '🇸🇪' },
        { name: 'Switzerland', flag: '🇨🇭' },
        { name: 'Syria', flag: '🇸🇾' },
        { name: 'Taiwan', flag: '🇹🇼' },
        { name: 'Tajikistan', flag: '🇹🇯' },
        { name: 'Tanzania', flag: '🇹🇿' },
        { name: 'Thailand', flag: '🇹🇭' },
        { name: 'Togo', flag: '🇹🇬' },
        { name: 'Tonga', flag: '🇹🇴' },
        { name: 'Trinidad and Tobago', flag: '🇹🇹' },
        { name: 'Tunisia', flag: '🇹🇳' },
        { name: 'Turkey', flag: '🇹🇷' },
        { name: 'Turkmenistan', flag: '🇹🇲' },
        { name: 'Tuvalu', flag: '🇹🇻' },
        { name: 'Uganda', flag: '🇺🇬' },
        { name: 'Ukraine', flag: '🇺🇦' },
        { name: 'United Arab Emirates', flag: '🇦🇪' },
        { name: 'United States', flag: '🇺🇸' },
        { name: 'Uruguay', flag: '🇺🇾' },
        { name: 'Uzbekistan', flag: '🇺🇿' },
        { name: 'Vanuatu', flag: '🇻🇺' },
        { name: 'Vatican City', flag: '🇻🇦' },
        { name: 'Venezuela', flag: '🇻🇪' },
        { name: 'Vietnam', flag: '🇻🇳' },
        { name: 'Yemen', flag: '🇾🇪' },
        { name: 'Zambia', flag: '🇿🇲' },
        { name: 'Zimbabwe', flag: '🇿🇼' }
        ];

    const defaultCountryDisplay = allCountries.slice(0, 10);


  
    const handleCountrySearch = (event) => {
        setSearchTerm(event.target.value);
        if (event.target.value === '') {
          setDisplayedCountries(defaultCountryDisplay);
        } else {
          const filteredCountries = allCountries.filter(country =>
            country.name.toLowerCase().includes(event.target.value.toLowerCase())
          );
          setDisplayedCountries(filteredCountries);
        }
      };
      const handleFocus = () => {
        setIsFocused(true);
        setDisplayedCountries(defaultCountryDisplay);
      };
    
      const handleBlur = () => {
        setTimeout(() => setIsFocused(false), 200);
      };

    
      const handleCountrySelect = (country) => {
        console.log('Country selected in Country component:', country);
        setSelectedCountry(country);
    };
    
      const handleCountryRemove = () => {
        setSelectedCountry(null);
      };


    const CountrySearchStyle = {
        width: '100%', // Make the search box wider
        marginBottom: '10px' // Add space below the search box
    };

    
    const selectedCountryStyle = {
        padding: '10px',
        marginBottom: '10px', // Add space below the selected country
        background: '#eee',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    };

    
    const removeButtonStyle = {
        padding: '2px 5px',
        background: 'red',
        color: 'white',
        cursor: 'pointer',
        borderRadius: '4px',
    };

    
      
      const listStyle = {
        display: isFocused ? 'block' : 'none',
        position: 'absolute',
        zIndex: 1000,
        background: '#fff',
        border: '1px solid #ddd',
        borderRadius: '4px',
        width: '100%',
        maxHeight: '200px',
        overflowY: 'auto',
        marginTop: '5px'
      };
    
      const countryStyle = {
        padding: '10px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center'
      };

      
    
      return (
        <div style={{ position: 'relative', marginBottom: '20px' }}>
            {!selectedCountry ? (
                <>
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={handleCountrySearch}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        placeholder="Select a country"
                        style={{ width: '100%', padding: '10px', marginBottom: '10px', border: '1px solid #ddd', borderRadius: '4px' }}
                    />
                    <ul style={listStyle}>
                        {displayedCountries.map(country => (
                            <li key={country.name} style={countryStyle} onClick={() => handleCountrySelect(country)}>
                                {country.flag} {country.name}
                            </li>
                        ))}
                    </ul>
                </>
            ) : null}
        </div>
    );
    };
    
    export default Country;