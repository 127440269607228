import React, { useState } from 'react';
import axios from 'axios';

const UnsubscribeEmail = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post('https://bapi.dataremotely.com/unsubscribe_email', { email });
      alert('You have been unsubscribed successfully.');
    } catch (error) {
      alert('An error occurred while trying to unsubscribe.');
    }
  };

  // Styles
  const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  };

  const inputStyle = {
    width: '300px', // Set the width of the input field
    margin: '0.5rem',
    padding: '1rem',
    fontSize: '1rem',
  };

  const buttonStyle = {
    width: '315px', // Match the input field width plus padding
    padding: '1rem',
    margin: '0.5rem',
    fontSize: '1rem',
    cursor: 'pointer',
  };

  return (
    <form onSubmit={handleSubmit} style={formStyle}>
      <label>
        Email:
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          style={inputStyle}
        />
      </label>
      <button type="submit" style={buttonStyle}>Unsubscribe</button>
    </form>
  );
};

export default UnsubscribeEmail;